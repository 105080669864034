import {
  Box,
  Button,
  Paper,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  onDone: () => void;
};

export default function ShareDetailsModal({ onDone }: Props) {
  const theme = useTheme();
  const { transcriptionId } = useTranscriptionContext();

  const qrCodeUrl = React.useMemo(() => {
    return `${process.env.REACT_APP_SUBSCRIBER_BASE_URL}/transcript/${transcriptionId}`;
  }, [transcriptionId]);

  const downloadSVG = () => {
    const svg = document.getElementById("qrCode");
    if (!svg) return;

    const serializer = new XMLSerializer();
    const svgBlob = new Blob([serializer.serializeToString(svg)], {
      type: "image/svg+xml;charset=utf-8",
    });

    const url = URL.createObjectURL(svgBlob);

    const img = new Image();

    img.onload = () => {
      const width = 250;
      const height = 250;
      const padding = 25; // Reduce padding to half (was 50px)
      const scale = 2; // For higher resolution
      // Create canvas with appropriate dimensions including padding
      const canvas = document.createElement("canvas");
      canvas.width = (width + padding * 2) * scale;
      canvas.height = (height + padding * 2) * scale;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      // Scale everything
      ctx.scale(scale, scale);

      // Set white background for entire canvas
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, width + padding * 2, height + padding * 2);

      // Draw QR code in the center with padding
      ctx.drawImage(img, padding, padding, width, height);

      canvas.toBlob((pngBlob) => {
        if (!pngBlob) return;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pngBlob);
        link.download = `QR Code_event_${transcriptionId}.png`;

        // Trigger the download
        link.click();
        URL.revokeObjectURL(url);
        URL.revokeObjectURL(link.href);
      }, "image/png");
    };

    img.onerror = (err) => {
      console.error("Failed to render SVG to PNG", err);
      URL.revokeObjectURL(url);
    };

    img.src = url;
  };

  const handleDoneClick = () => {
    onDone();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 300,
        width: "600px",
        maxWidth: "90vw",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleDoneClick}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
            color: theme.palette.grey[500],
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          id="modal-modal-title"
          variant="h5"
          textAlign="center"
          sx={{
            mt: 2,
            mb: 2,
            px: 4, // Add horizontal padding to prevent overlap with the close button
          }}
        >
          Access live translations on your device
        </Typography>

        <Box
          display="flex"
          marginY="20px"
          justifyContent="center"
          alignItems="center"
        >
          {/* <img src={qrCode} alt="QR Code" height="300px" /> */}
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            <QRCode
              id="qrCode"
              value={qrCodeUrl}
              size={250}
              bgColor="#FFFFFF"
              fgColor="#000000"
            />
          </Box>
        </Box>

        <Typography textAlign={"center"}>
          <Button variant="text" onClick={downloadSVG}>
            Download QR Code
          </Button>
        </Typography>

        <Typography textAlign={"center"} sx={{ mt: 2 }}>
          Or type the following URL in your browser:
        </Typography>

        <Typography textAlign={"center"} sx={{ mt: 2, fontWeight: "bold" }}>
          {qrCodeUrl}
        </Typography>
      </Box>
    </Paper>
  );
}
