import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { Divider, Stack, useTheme, Box } from "@mui/material";
import React from "react";
import HeaderTranscriptionSection from "./HeaderTranscriptionSection.tsx";
import HeaderAudioInputSection from "./HeaderAudioInputSection.tsx";
import HeaderThemeSection from "./HeaderThemeSection.tsx";
import HeaderControlsSection from "./HeaderControlsSection.tsx";
import HeaderMoreSection from "./HeaderMoreSection.tsx";
import { useTranscriptionDisplay } from "../../../context/TranscriptionDisplay.tsx";
import HeaderLanguageSection from "./HeaderTranscriptionLanguage.tsx";
import HeaderCaptionPositionSection from "./HeaderCaptionPositionSection.tsx";

export default function RunMeetingHeader() {
  const theme = useTheme();
  const { showHeader, toggleShowHeader, transcriptionPositionType } =
    useTranscriptionDisplay();
  const { transcriptionStatus } = useTranscriptionContext();
  const [shouldAutoHide, setShouldAutoHide] = React.useState(false);
  const hoverTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const handleHeaderHover = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }

    if (!showHeader) {
      toggleShowHeader();
    }
  };

  const handleHeaderLeave = () => {
    if (shouldAutoHide && showHeader) {
      hoverTimeout.current = setTimeout(() => {
        toggleShowHeader();
      }, 500);
    }
  };

  React.useEffect(() => {
    setShouldAutoHide(transcriptionStatus === "started");
  }, [transcriptionStatus]);

  const [, setHasAudioInputDevices] = React.useState(false);

  React.useEffect(() => {
    const checkInputDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setHasAudioInputDevices(audioInputDevices.length > 1);
    };
    checkInputDevices();
  }, []);

  return (
    <Box
      onMouseEnter={handleHeaderHover}
      onMouseLeave={handleHeaderLeave}
      id="RunMeetingHeader"
      className={showHeader ? "show" : "hide"}
      onClick={showHeader ? undefined : toggleShowHeader}
      sx={{
        position: "absolute",
        zIndex: 100,
        width: "calc(100% - 20px)",
        margin: "10px",
        borderRadius: "10px",
        bottom: transcriptionPositionType === "fixed-top" ? 0 : "unset",
        padding: showHeader ? "10px 20px" : "10px 5px",
        backgroundColor: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        transition: "opacity 0.3s ease-in-out",
        opacity: showHeader ? 1 : 0,
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)", // Add drop shadow
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{
            flex: 1,
            justifyContent: "flex-start",
          }}
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
        >
          <a
            href="https://www.sunflowerai.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              height: "30px",
              marginRight: "18px",
              marginLeft: "5px",
            }}
          >
            <Box
              component="img"
              src={"/sunflower_light.svg"}
              alt={"Sunflower logo"}
              sx={{
                height: "100%",
              }}
            />
          </a>
          <Stack direction="row" gap={1}>
            <Box
              sx={{
                display: {
                  xs: "none",
                  "@media (min-width:1200px)": { display: "block" },
                },
              }}
            >
              <HeaderTranscriptionSection />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: {
                  xs: "none",
                  "@media (min-width:1400px)": { display: "block" },
                },
              }}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  "@media (min-width:1000px)": { display: "block" },
                },
              }}
            >
              <HeaderThemeSection />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: {
                  xs: "none",
                  "@media (min-width:1200px)": { display: "block" },
                },
              }}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  "@media (min-width:1200px)": { display: "block" },
                },
              }}
            >
              <HeaderCaptionPositionSection />
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{
            flex: 1,
            justifyContent: "center",
            display: { xs: "none", md: "flex" },
          }}
        >
          <HeaderControlsSection isActive={false} />
        </Stack>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <HeaderControlsSection isActive={false} />
        </Box>

        <Stack
          direction="row"
          gap={1}
          sx={{
            flex: 1,
            justifyContent: "flex-end",
          }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <HeaderLanguageSection />
          <HeaderAudioInputSection />
          <HeaderMoreSection />
        </Stack>
      </Stack>
    </Box>
  );
}
