import React, { useCallback } from "react";
import { TranscriptionType } from "../containers/RunMeeting/components/HeaderTranscriptionSection.tsx";
import { useTheme } from "@mui/material";
import { TranscriptionProvider } from "./Transcription.tsx"; // Added this line
import { z } from "zod";
export interface TranscriptionTheme {
  backgroundColor: string;
  defaultBackgroundColor: string;
  fontFamily: string;
  fontSize: string;
  fontColor: string;
  dropShadow: boolean;
  captionBackgroundType: "black" | "transparent";
  captioBackgroundOpacity?: number;
}

export const TranscriptionThemeSchema = z.object({
  backgroundColor: z.string(),
  defaultBackgroundColor: z.string(),
  fontFamily: z.string(),
  fontColor: z.string(),
  fontSize: z.string(),
  dropShadow: z.boolean(),
  captionBackgroundType: z.enum(["black", "transparent"]),
  captioBackgroundOpacity: z.number().optional(),
});

export const TranscriptPositionSchema = z.enum([
  "fixed-top",
  "fixed-bottom",
  "floating",
]);

export interface TranscriptionPosition {
  x: number;
  y: number;
}

export interface PresentationSource {
  sourceApp?: string;
  sourceUrl?: string;
  originalUrl?: string;
}

export type TranscriptionPositionType =
  | "fixed-top"
  | "fixed-bottom"
  | "floating";

interface TranscriptionDisplayContextType {
  setTranscriptionType: (transcriptionType: TranscriptionType) => void;
  setTranscriptionPosition: (
    transcriptionPosition: TranscriptionPosition
  ) => void;
  setPresentationSource: React.Dispatch<
    React.SetStateAction<PresentationSource>
  >;
  setBackgroundColor: (color: string) => void;
  setFontTheme: (
    fontFamily: string,
    fontSize: string,
    fontColor: string,
    captionBackgroundType?: "black" | "transparent",
    captionBackgroundOpcity?: number
  ) => void;
  toggleShowHeader: () => void;
  setShowHeader: (show: boolean) => void;
  toggleShowShareDetails: () => void;
  setShowPresentationSourceModal: (show: boolean) => void;
  setShowShareDetailsModal: (show: boolean) => void;
  setTranscriptionPositionTypeAndUpdateBackground: (
    newPositionType: TranscriptionPositionType
  ) => void;
  setShowCustomPhrasesModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCustomPhrasesModal: boolean;
  transcriptionPositionType: TranscriptionPositionType;
  showHeader: boolean;
  showShareDetails: boolean;
  presentationSource: PresentationSource;
  transcriptionType: TranscriptionType;
  transcriptionPosition: TranscriptionPosition;
  transcriptionDisplayTheme: TranscriptionTheme;
  showPresentationSourceModal: boolean;
  toggleDropShadow: (forcedState?: boolean) => void;
  resetTheme: () => void;
}

const TranscriptionDisplayContext = React.createContext<
  TranscriptionDisplayContextType | undefined
>(undefined);

type TranscriptionDisplayProviderProps = React.PropsWithChildren;

export const TranscriptionDisplayProvider: React.FC<
  TranscriptionDisplayProviderProps
> = ({ children }) => {
  const theme = useTheme();
  const [showHeader, setShowHeader] = React.useState<boolean>(true);
  const [showShareDetails, setShowShareDetails] = React.useState<boolean>(true);
  const [transcriptionType, setTranscriptionType] =
    React.useState<TranscriptionType>("caption-only");
  const [showPresentationSourceModal, setShowPresentationSourceModal] =
    React.useState<boolean>(false);
  const [showCustomPhrasesModal, setShowCustomPhrasesModal] =
    React.useState<boolean>(false);
  const [presentationSource, setPresentationSource] =
    React.useState<PresentationSource>({});
  const [transcriptionPosition, setTranscriptionPosition] =
    React.useState<TranscriptionPosition>({ x: 100, y: 100 });
  const [transcriptionPositionType, setTranscriptionPositionType] =
    React.useState<TranscriptionPositionType>("floating");
  const defaultThemeSetup = React.useRef<TranscriptionTheme>({
    backgroundColor: theme.palette.background.paper,
    defaultBackgroundColor: theme.palette.background.paper, // Add this line
    fontFamily: "sans-serif",
    fontSize: "medium",
    fontColor: "#fff",
    dropShadow: false,
    captionBackgroundType: "black",
    captioBackgroundOpacity: 100,
  });
  const [transcriptionDisplayTheme, setTranscriptionDisplayTheme] =
    React.useState<TranscriptionTheme>(defaultThemeSetup.current);

  React.useEffect(() => {
    const lastTheme = localStorage.getItem("transcriptionDisplayTheme");
    if (lastTheme) {
      try {
        const parsedTheme = TranscriptionThemeSchema.parse(
          JSON.parse(lastTheme)
        );

        setTranscriptionDisplayTheme(parsedTheme);
      } catch (e) {
        console.warn("Error parsing transcription display theme", e);
      }
    }

    const lastPositionType = localStorage.getItem("transcriptionPositionType");
    if (lastPositionType) {
      try {
        const parsedPositionType =
          TranscriptPositionSchema.parse(lastPositionType);
        setTranscriptionPositionType(parsedPositionType);
      } catch (e) {
        console.warn("Error parsing transcription position type", e);
      }
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem(
      "transcriptionDisplayTheme",
      JSON.stringify(transcriptionDisplayTheme)
    );
  }, [transcriptionDisplayTheme]);

  React.useEffect(() => {
    localStorage.setItem(
      "transcriptionPositionType",
      transcriptionPositionType
    );
  }, [transcriptionPositionType]);

  const updateDropShadow = useCallback(
    (
      positionType: TranscriptionPositionType,
      backgroundType: "black" | "transparent"
    ) => {
      setTranscriptionDisplayTheme((prev) => ({
        ...prev,
        dropShadow:
          positionType === "floating" && backgroundType === "black"
            ? prev.dropShadow
            : false,
      }));
    },
    []
  );

  const setTranscriptionPositionTypeAndUpdateBackground = useCallback(
    (newPositionType: TranscriptionPositionType) => {
      setTranscriptionPositionType(newPositionType);
      updateDropShadow(
        newPositionType,
        transcriptionDisplayTheme.captionBackgroundType
      );
    },
    [transcriptionDisplayTheme.captionBackgroundType, updateDropShadow]
  );

  const setBackgroundColor = useCallback((color: string) => {
    setTranscriptionDisplayTheme((prev: TranscriptionTheme) => ({
      ...prev,
      backgroundColor: color,
    }));
  }, []);

  const doSetTranscriptionPosition = useCallback(
    (position: TranscriptionPosition) => {
      setTranscriptionPosition(position);
    },
    []
  );

  const setFontTheme = useCallback(
    (
      fontFamily: string,
      fontSize: string,
      fontColor: string,
      captionBackgroundType?: "black" | "transparent",
      captionBackgroundOpacity?: number
    ) => {
      setTranscriptionDisplayTheme((prev) => {
        const newTheme = {
          ...prev,
          fontFamily,
          fontSize,
          fontColor,
          captionBackgroundType:
            captionBackgroundType || prev.captionBackgroundType,
          dropShadow:
            captionBackgroundType === "transparent" ? false : prev.dropShadow,
          captioBackgroundOpacity:
            captionBackgroundOpacity === 0
              ? 0
              : captionBackgroundOpacity || prev.captioBackgroundOpacity,
        };
        return newTheme;
      });
    },
    []
  );

  const toggleShowHeader = () => {
    setShowHeader((prev) => !prev);
  };

  const toggleShowShareDetails = () => {
    setShowShareDetails((prev) => !prev);
  };

  const toggleDropShadow = useCallback((forcedState?: boolean) => {
    setTranscriptionDisplayTheme((prev) => ({
      ...prev,
      dropShadow: forcedState !== undefined ? forcedState : !prev.dropShadow,
    }));
  }, []);

  const resetTheme = useCallback(() => {
    setTranscriptionDisplayTheme(defaultThemeSetup.current);
  }, []);

  return (
    <TranscriptionProvider transcriptionType={transcriptionType}>
      <TranscriptionDisplayContext.Provider
        value={{
          setTranscriptionType,
          setTranscriptionPosition: doSetTranscriptionPosition,
          setFontTheme,
          setBackgroundColor,
          toggleShowHeader,
          setPresentationSource,
          toggleShowShareDetails,
          setShowHeader,
          showHeader,
          showShareDetails,
          transcriptionType,
          transcriptionPosition,
          transcriptionDisplayTheme,
          presentationSource,
          showPresentationSourceModal,
          setShowShareDetailsModal: setShowShareDetails,
          setShowPresentationSourceModal,
          setTranscriptionPositionTypeAndUpdateBackground,
          transcriptionPositionType,
          setShowCustomPhrasesModal,
          showCustomPhrasesModal,
          toggleDropShadow,
          resetTheme,
        }}
      >
        {children}
      </TranscriptionDisplayContext.Provider>
    </TranscriptionProvider>
  );
};

export const useTranscriptionDisplay = () => {
  const context = React.useContext(TranscriptionDisplayContext);
  if (context === undefined) {
    throw new Error(
      "useTranscriptionDisplay must be used within a TranscriptionDisplayProvider"
    );
  }
  return context;
};
