import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  Box,
  Button,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Slider,
  Divider,
} from "@mui/material";
import OpacityIcon from "@mui/icons-material/Opacity";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useTranscriptionDisplay } from "../../../context/TranscriptionDisplay.tsx";
import { MuiColorInput } from "mui-color-input";

export default function HeaderThemeSection() {
  const {
    setBackgroundColor,
    setFontTheme,
    transcriptionDisplayTheme,
    toggleDropShadow,
    transcriptionType,
    transcriptionPositionType,
    resetTheme,
  } = useTranscriptionDisplay();

  const [fontSettingsOpen, setFontSettingsOpen] =
    React.useState<boolean>(false);
  const [colorSettingsOpen, setColorSettingsOpen] =
    React.useState<boolean>(false);
  const [fontSettingsAnchorElement, setFontSettingsAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const [colorSettingsAnchorElement, setColorSettingsAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const popoverId = fontSettingsOpen ? "font-settings-popover" : undefined;
  const colorSettingsPopoverId = colorSettingsOpen
    ? "color-settings-popover"
    : undefined;

  const isFullScreenMode = transcriptionType === "full-screen";

  const handleColorPickerBoxClick = (e: React.MouseEvent<HTMLElement>) => {
    setColorSettingsAnchorElement(e.currentTarget);
    setColorSettingsOpen(true);
  };

  const handleBackgroundColorChange = (color: string) => {
    setBackgroundColor(color);
  };

  const handlePresetBackgroundColor = (
    event: React.MouseEvent<HTMLElement>,
    newColor: string | null
  ) => {
    if (newColor !== null) {
      setBackgroundColor(newColor);
    }
  };

  const handleFontColorChange = (color: string) => {
    setFontTheme(
      transcriptionDisplayTheme.fontFamily,
      transcriptionDisplayTheme.fontSize,
      color,
      transcriptionDisplayTheme.captionBackgroundType
    );
  };

  const handleFontFamilyChange = (fontFamily: string) => {
    setFontTheme(
      fontFamily,
      transcriptionDisplayTheme.fontSize,
      transcriptionDisplayTheme.fontColor,
      transcriptionDisplayTheme.captionBackgroundType
    );
  };
  const handleFontSizeChange = (fontSize: string) => {
    setFontTheme(
      transcriptionDisplayTheme.fontFamily,
      fontSize,
      transcriptionDisplayTheme.fontColor,
      transcriptionDisplayTheme.captionBackgroundType
    );
  };

  const handleCaptionBackgroundTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newBackgroundType: "black" | "transparent"
  ) => {
    if (newBackgroundType !== null) {
      setFontTheme(
        transcriptionDisplayTheme.fontFamily,
        transcriptionDisplayTheme.fontSize,
        transcriptionDisplayTheme.fontColor,
        newBackgroundType
      );
      // Instead of calling toggleDropShadow, we'll update the drop shadow state in the setFontTheme function
    }
  };

  const hanldeFontSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setFontSettingsAnchorElement(event.currentTarget);
    setFontSettingsOpen(true);
  };

  const isDropShadowDisabled =
    transcriptionDisplayTheme.captionBackgroundType === "transparent" ||
    transcriptionPositionType !== "floating" ||
    isFullScreenMode;

  return (
    <RunMeetingHeaderSection label="Theme">
      <>
        <Tooltip
          title={isFullScreenMode ? "Not available in full-screen mode" : ""}
        >
          <span>
            <Button
              sx={{
                color: "white",
              }}
              onClick={isFullScreenMode ? undefined : handleColorPickerBoxClick}
            >
              <Box
                sx={{
                  backgroundColor: transcriptionDisplayTheme.backgroundColor,
                  width: "20px",
                  height: "20px",
                  borderRadius: "5px",
                  position: "relative",
                  cursor: isFullScreenMode ? "not-allowed" : "pointer",
                  opacity: isFullScreenMode ? 0.5 : 1,
                  pointerEvents: isFullScreenMode ? "none" : "auto",
                }}
              ></Box>
              <ExpandMoreOutlinedIcon />
            </Button>
          </span>
        </Tooltip>
        <Popover
          id={colorSettingsPopoverId}
          open={colorSettingsOpen}
          anchorEl={colorSettingsAnchorElement}
          onClose={() => setColorSettingsOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              padding: 1,
            }}
          >
            <Stack direction="column" spacing={2}>
              <MuiColorInput
                size="small"
                format="hex"
                value={transcriptionDisplayTheme.backgroundColor}
                disabled={isFullScreenMode}
                onChange={(newColor) => handleBackgroundColorChange(newColor)}
              />
              <ToggleButtonGroup
                exclusive
                size="small"
                value={transcriptionDisplayTheme.backgroundColor}
                disabled={isFullScreenMode}
                onChange={handlePresetBackgroundColor}
              >
                <ToggleButton value="#1f1e1d">Default</ToggleButton>
                <ToggleButton value="#000000">Black</ToggleButton>
                <ToggleButton value="#00ff00">Green</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Box>
        </Popover>
      </>
      <>
        <Button
          onClick={hanldeFontSettingsClick}
          sx={{
            textTransform: "none",
            color: "white",
          }}
        >
          Aa
          <ExpandMoreOutlinedIcon />
        </Button>
        <Popover
          id={popoverId}
          open={fontSettingsOpen}
          anchorEl={fontSettingsAnchorElement}
          onClose={() => setFontSettingsOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ padding: 1 }}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Caption</Typography>
                <Button
                  onClick={resetTheme}
                  style={{
                    color: "#999",
                    textTransform: "none",
                  }}
                >
                  Reset Theme
                </Button>
              </Stack>
              <MuiColorInput
                size="small"
                format="hex"
                value={transcriptionDisplayTheme.fontColor}
                onChange={(newColor) => handleFontColorChange(newColor)}
              />
              <Stack direction="row" spacing={2}>
                <ToggleButtonGroup
                  exclusive
                  size="small"
                  value={transcriptionDisplayTheme.fontFamily}
                  onChange={(e, value) => handleFontFamilyChange(value)}
                >
                  <ToggleButton value="serif">Serif</ToggleButton>
                  <ToggleButton value="sans-serif">Sans Serif</ToggleButton>
                </ToggleButtonGroup>
                <Tooltip
                  title={
                    isFullScreenMode ? "Not available in full-screen mode" : ""
                  }
                >
                  <span>
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={transcriptionDisplayTheme.fontSize}
                      onChange={(e, value) => handleFontSizeChange(value)}
                      disabled={isFullScreenMode}
                    >
                      <ToggleButton value="small">
                        <Typography sx={{ fontSize: "10px" }}>Small</Typography>
                      </ToggleButton>
                      <ToggleButton value="medium">Medium</ToggleButton>
                      <ToggleButton value="large">
                        <Typography sx={{ fontSize: "20px" }}>Large</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </span>
                </Tooltip>
              </Stack>
              <Divider />
              <Typography variant="h6">Caption Background</Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Tooltip
                  title={
                    isFullScreenMode ? "Not available in full-screen mode" : ""
                  }
                >
                  <span>
                    <ToggleButtonGroup
                      exclusive
                      size="small"
                      value={transcriptionDisplayTheme.captionBackgroundType}
                      onChange={handleCaptionBackgroundTypeChange}
                      disabled={isFullScreenMode}
                    >
                      <ToggleButton value="black">Black</ToggleButton>
                      <ToggleButton value="transparent">
                        Transparent
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </span>
                </Tooltip>
                <Stack direction="row" spacing={2}>
                  <OpacityIcon />
                  <Slider
                    min={0}
                    max={100}
                    value={
                      transcriptionDisplayTheme.captioBackgroundOpacity ||
                      transcriptionDisplayTheme.captioBackgroundOpacity === 0
                        ? Math.round(
                            transcriptionDisplayTheme.captioBackgroundOpacity *
                              100
                          )
                        : 100
                    }
                    onChange={(_, val) => {
                      setFontTheme(
                        transcriptionDisplayTheme.fontFamily,
                        transcriptionDisplayTheme.fontSize,
                        transcriptionDisplayTheme.fontColor,
                        transcriptionDisplayTheme.captionBackgroundType,
                        val === 0 ? 0 : (val as number) / 100
                      );
                    }}
                    size="small"
                    disabled={isFullScreenMode}
                    sx={{ color: "white", width: "100px" }}
                    defaultValue={100}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </Stack>
              <Tooltip
                title={
                  isFullScreenMode
                    ? "Not available in full-screen mode"
                    : isDropShadowDisabled
                    ? "Drop shadow is only available for floating captions with a black background"
                    : ""
                }
              >
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={transcriptionDisplayTheme.dropShadow}
                        onChange={(event) => {
                          toggleDropShadow(event.target.checked);
                        }}
                        size="small"
                        disabled={isDropShadowDisabled}
                      />
                    }
                    label="Drop Shadow"
                    sx={{
                      opacity: isDropShadowDisabled ? 0.5 : 1,
                      pointerEvents: isDropShadowDisabled ? "none" : "auto",
                    }}
                  />
                </span>
              </Tooltip>
            </Stack>
          </Box>
        </Popover>
      </>
    </RunMeetingHeaderSection>
  );
}
